import axios from "axios";
import { getToken } from "./auth";
import { toast } from "react-hot-toast";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    // Authorization: getCookie("Authentication"),
    // Refresh: getCookie("Refresh"),
  },
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    // 액세스 토큰 만료
    if (err.response.data.statusCode === 401) {
      getToken();
    } else if (err.response.data.statusCode === 400) {
      // toast.error(err.response.data.message);
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export default instance;
