import { createTheme } from "@mui/material";
import { blue, blueGrey, red, teal } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: { main: blue["A700"] },
    secondary: { main: "#398E3D" },
    error: { main: red["A700"] },
    info: { main: blueGrey["A700"] },
    // success: { main: blueGrey["A700"] },
    // warning: { main: blueGrey["A700"] },
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
          fontFamily: "inherit",
          fontWeight: "inherit",
          borderWidth: "1.5px",
          ":hover": { borderWidth: "1.5px" },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bbb !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "inherit",
          fontSize: "inherit",
          fontFamily: "inherit",
          fontWeight: "inherit",
        },
      },
    },
    MuiLink: {
      styleOverrides: { root: { textDecoration: "none", color: "inherit" } },
    },
    MuiPaper: {
      styleOverrides: { root: { color: "inherit" } },
    },
    MuiOutlinedInput: {
      styleOverrides: { root: { color: "inherit", fontSize: "inherit" } },
    },
    MuiSvgIcon: {
      styleOverrides: { root: { fontSize: "1rem" } },
    },
    MuiAppBar: { styleOverrides: { root: { color: "inherit" } } },
    MuiMenuItem: { styleOverrides: { root: { fontSize: "inherit" } } },
    MuiFormLabel: {
      styleOverrides: {
        root: { fontSize: "inherit", color: "inherit !important" },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: "40px",
          ".MuiSwitch-thumb": { width: "18px", height: "18px" },
          ".MuiSwitch-switchBase": {
            position: "absolute",
            top: "2px",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
          fontFamily: "inherit",
        },
      },
    },
  },
});
