import { ChevronLeft } from "@mui/icons-material";
import { Box, Drawer, IconButton, styled, Toolbar } from "@mui/material";
import React from "react";
import { menuList } from "../../utils/constants/menuList";
import { SIDEBAR_WIDTH } from "../../utils/constants/values";
import { LayoutPropsType } from "./Header";
import MenuItem from "./MenuItem";
import ParentItem from "./ParentItem";
import { blue, blueGrey, indigo } from "@mui/material/colors";

const SideBar = ({ isOpenSideBar, handleToggleSideBar }: LayoutPropsType) => {
  return (
    <StyledDrawer open={isOpenSideBar} variant="permanent" sx={{ zIndex: 2 }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          minHeight: "75px !important",
          boxShadow:
            "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
        }}
      >
        <Box
          display="flex"
          justifyContent="end"
          alignItems="center"
          width="100%"
          height="100%"
          borderBottom={`1.5px solid ${blueGrey[100]}`}
        >
          <IconButton
            onClick={handleToggleSideBar}
            sx={{ padding: 0, color: blue["A700"] }}
          >
            <ChevronLeft
              sx={{
                fontSize: 22,
              }}
            />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        component="ul"
        p="10px 15px"
        sx={{
          overflowY: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {menuList.map((menu) =>
          menu.children ? (
            <ParentItem item={menu} key={menu.name} />
          ) : (
            <MenuItem item={menu} key={menu.name} />
          )
        )}
      </Box>
    </StyledDrawer>
  );
};

export default SideBar;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: SIDEBAR_WIDTH,
    height: "100vh",
    borderRight: `1px solid ${blueGrey[100]}`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(0),
      },
    }),
  },
}));
