import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import WelcomeImg from "../../assets/images/welcome.png";
import LoginForm from "../../components/auth/LoginForm";
import Logo from "../../components/auth/Logo";
import { blue } from "@mui/material/colors";

const Login = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Grid container height="100vh">
      <Logo />
      {!isMobile && (
        <Grid container item xs={8} justifyContent="center" alignItems="center">
          <img src={WelcomeImg} alt="이미지" width="500" />
        </Grid>
      )}
      <Grid
        container
        item
        sm={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={5}
        sx={{ backgroundColor: blue[50] }}
      >
        <Typography
          component="h2"
          variant="h2"
          fontSize={22}
          fontWeight={700}
          mb="30px"
        >
          21세기전파상 어드민
        </Typography>
        <LoginForm />
      </Grid>
    </Grid>
  );
};

export default Login;
