import { Link } from "@mui/material";
import React from "react";
import LogoImg from "../../assets/images/logo_black.png";

const Logo = () => {
  return (
    <Link href="/" position="fixed" p="35px">
      <img src={LogoImg} alt="logo" width={160} />
    </Link>
  );
};

export default Logo;
