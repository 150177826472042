import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Link,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { SIDEBAR_WIDTH } from "../../utils/constants/values";
import { Logout, Menu } from "@mui/icons-material";
import { logout } from "../../api/auth";
import { blue, indigo } from "@mui/material/colors";

export interface LayoutPropsType {
  isOpenSideBar: boolean;
  handleToggleSideBar: () => void;
}

const Header = ({ isOpenSideBar, handleToggleSideBar }: LayoutPropsType) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const handleLogout = () => {
    logout();
  };

  return (
    <StyledAppBar
      open={isOpenSideBar}
      sx={{
        backgroundColor: blue["A700"],
        boxShadow: "none",
        zIndex: 3,
      }}
    >
      <Toolbar
        sx={{
          minHeight: "75px !important",
        }}
      >
        <IconButton
          onClick={handleToggleSideBar}
          sx={{
            mr: isMobile ? "5px" : "16px",
            ...(isOpenSideBar && { display: "none" }),
          }}
        >
          <Menu
            sx={{
              fontSize: isMobile ? 18 : 22,
            }}
          />
        </IconButton>
        <Grid container justifyContent="space-between" alignItems="center">
          <Link href="/">
            <Typography
              component="h1"
              fontSize={isMobile ? 18 : 22}
              fontWeight={700}
              color={"#fff"}
            >
              21세기전파상 어드민
            </Typography>
          </Link>
          <Button
            onClick={handleLogout}
            sx={{ color: "#fff", fontSize: isMobile ? 14 : 16 }}
          >
            <Grid container alignItems="center" gap={1}>
              <Logout /> 로그아웃
            </Grid>
          </Button>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: { theme?: Theme; open: boolean }) => ({
  zIndex: theme!.zIndex.drawer + 1,
  transition: theme!.transitions.create(["width", "margin"], {
    easing: theme!.transitions.easing.sharp,
    duration: theme!.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: SIDEBAR_WIDTH,
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    transition: theme!.transitions.create(["width", "margin"], {
      easing: theme!.transitions.easing.sharp,
      duration: theme!.transitions.duration.enteringScreen,
    }),
  }),
}));
