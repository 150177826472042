import { Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/auth";
import { RegisterType } from "../../types/auth";
import { StatusCodeType } from "../../types/common";
import EmptyGrid from "../common/EmptyGrid";
import HookTextField from "../common/HookTextField";
import { blue, indigo } from "@mui/material/colors";

const RegisterForm = () => {
  const navigate = useNavigate();

  const { control, handleSubmit, getValues, setError } =
    useForm<RegisterType>();

  const handleRegister = async (data: RegisterType) => {
    try {
      const { passwordConfirm, ...others } = data;
      const { statusCode }: StatusCodeType = await register(others);
      if (statusCode === 200) navigate("/login");
    } catch (e: any) {
      if (e.response.status === 400) {
        // 중복된 아이디/이메일
        setError("userId", { message: "중복된 아이디/이메일입니다" });
        setError("email", { message: "중복된 아이디/이메일입니다" });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <Grid
        container
        flexDirection="column"
        gap={3}
        p="30px"
        borderRadius="7px"
        sx={{ backgroundColor: "#fff" }}
      >
        <Grid container gap={3}>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>아이디 *</Typography>
            <HookTextField
              control={control}
              name="userId"
              rules={{
                minLength: {
                  value: 5,
                  message: "5글자 이상을 입력해주세요",
                },
              }}
              type="text"
              size="small"
              fullWidth
              placeholder="5글자 이상"
              autoFocus
            />
          </Grid>
          <EmptyGrid />
        </Grid>
        <Grid container gap={3}>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>이름 *</Typography>
            <HookTextField
              control={control}
              name="userName"
              rules={{
                pattern: {
                  value: /^[가-힣]+$/,
                  message: "한글만 입력해주세요 (자, 모음 X)",
                },
              }}
              type="text"
              size="small"
              fullWidth
              placeholder="한글만 입력해주세요 (자, 모음 X)"
            />
          </Grid>
          <EmptyGrid />
        </Grid>
        <Grid container gap={3}>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>비밀번호 *</Typography>
            <HookTextField
              control={control}
              name="password"
              rules={{
                minLength: {
                  value: 4,
                  message: "4글자 이상을 입력해주세요",
                },
                validate: (v: string) => {
                  const { passwordConfirm } = getValues();
                  return (
                    v === passwordConfirm || "비밀번호가 일치하지 않습니다"
                  );
                },
              }}
              type="password"
              size="small"
              fullWidth
              placeholder="4글자 이상"
            />
          </Grid>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>비밀번호 확인 *</Typography>
            <HookTextField
              control={control}
              name="passwordConfirm"
              rules={{
                validate: (v: string) => {
                  const { password } = getValues();
                  return v === password || "비밀번호가 일치하지 않습니다";
                },
              }}
              type="password"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container gap={3}>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>이메일 *</Typography>
            <HookTextField
              control={control}
              name="email"
              rules={{
                validate: (v: string) =>
                  v.split("@")[1] === "21market.kr" ||
                  "@21market.kr 계정을 입력해주세요",
              }}
              type="email"
              size="small"
              fullWidth
              placeholder="@21market.kr 계정을 입력해주세요"
            />
          </Grid>
          <Grid container item xs={12} sm gap={1.5}>
            <Typography>연락처 *</Typography>
            <HookTextField
              control={control}
              name="phone"
              rules={{
                validate: {
                  1: (v: number) => !isNaN(v) || "숫자만 입력해주세요 (- 제외)",
                  2: (v: string) =>
                    v.length === 11 || "11자리 숫자만 입력해주세요",
                  3: (v: string) =>
                    v.startsWith("010") ||
                    "[010]으로 시작하는 번호를 입력해주세요",
                },
              }}
              type="tel"
              size="small"
              fullWidth
              placeholder="숫자만 입력해주세요 (- 제외)"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          sx={{ my: "5px" }}
        >
          회원가입
        </Button>
        <Grid container justifyContent="center">
          <Typography mr="10px">이미 관리자 회원이신가요?</Typography>
          <Link
            href="/login"
            color={blue["A700"]}
            sx={{ ":hover": { opacity: "0.8" } }}
          >
            로그인 하러가기
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterForm;
