import { Grid, styled } from "@mui/material";
import React from "react";

const EmptyGrid = () => {
  return <StyledGrid />;
};

export default EmptyGrid;

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  flexGrow: 1,
}));
