import instance from "./root";
import { LoginType, RegisterType } from "../types/auth";
import { StatusCodeType } from "../types/common";
import axios from "axios";
import { setCookie } from "../utils/cookie";

const prefix = "/auth";

export const register = (data: RegisterType) => {
  try {
    return instance.post(`${prefix}/signup`, data);
  } catch (e: any) {
    return e.response.data;
  }
};

export const login = (data: LoginType) => {
  try {
    return instance.post(`${prefix}/login`, data);
  } catch (e: any) {
    return e.response.data;
  }
};

export const getToken = async () => {
  try {
    const { statusCode }: StatusCodeType = await instance.get(
      `${prefix}/refresh`
    );
    if (statusCode === 200) window.location.reload();
  } catch (e: any) {
    window.location.href = "/login";
  }
};

export const logout = async () => {
  try {
    const { statusCode }: StatusCodeType = await instance.post(
      `${prefix}/logout`
    );
    if (statusCode === 200) {
      window.location.href = "/login";
    }
  } catch (e: any) {
    return e.response.error;
  }
};

// https://developers.portone.io/docs/ko/api/rest-api-access-token
export const getImpToken = async () => {
  try {
    const {
      data: {
        response: { access_token },
      },
    } = await axios.post(
      `${process.env.REACT_APP_IMP_API_URL}/users/getToken`,
      {
        imp_key: process.env.REACT_APP_IMP_KEY,
        imp_secret: process.env.REACT_APP_IMP_SECRET,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    if (access_token) {
      setCookie("imp_token", access_token);
      return access_token;
    }
  } catch (e: any) {
    return e.response.error;
  }
};
