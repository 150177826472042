import React from "react";
import { Button, Grid, Link, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      gap={3}
    >
      <Typography fontSize={22} fontWeight={700}>
        존재하지 않는 페이지입니다.
      </Typography>
      <Link href="/">
        <Button variant="contained">홈으로 돌아가기</Button>
      </Link>
    </Grid>
  );
};

export default NotFound;
