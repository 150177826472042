import { Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/auth";
import { LoginType } from "../../types/auth";
import HookTextField from "../common/HookTextField";
import { blue, indigo } from "@mui/material/colors";

const LoginForm = () => {
  const navigate = useNavigate();

  const { control, handleSubmit, setError } = useForm<LoginType>();

  const handleLogin = async (data: LoginType) => {
    try {
      const { statusCode } = await login(data);
      if (statusCode === 200) navigate("/");
    } catch (e: any) {
      if (e.response.status === 400) {
        // 잘못된 아이디/비밀번호
        setError("userId", { message: "존재하지 않는 아이디/비밀번호입니다" });
        setError("password", {
          message: "존재하지 않는 아이디/비밀번호입니다",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Grid
        container
        flexDirection="column"
        gap={3}
        p="40px"
        borderRadius="7px"
        sx={{ background: "#fff" }}
      >
        <HookTextField
          control={control}
          name="userId"
          type="text"
          label="아이디 *"
          fullWidth
          placeholder="아이디를 입력해주세요"
          autoFocus
        />
        <HookTextField
          control={control}
          name="password"
          type="password"
          label="비밀번호 *"
          fullWidth
          placeholder="비밀번호를 입력해주세요"
        />
        <Button type="submit" variant="contained" size="large" fullWidth>
          로그인
        </Button>
        <Grid container justifyContent="center">
          <Typography mr="10px">관리자 회원이 아니신가요?</Typography>
          <Link
            href="/register"
            color={blue["A700"]}
            sx={{ ":hover": { opacity: "0.8" } }}
          >
            회원가입하기
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
