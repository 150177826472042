import { Control, useController } from "react-hook-form";
import { Box, TextField, styled, StandardTextFieldProps } from "@mui/material";
import React from "react";
import { red } from "@mui/material/colors";

type LoginNameType = "userId" | "password";
type RegisterNameType =
  | "userId"
  | "userName"
  | "password"
  | "passwordConfirm"
  | "email"
  | "phone";

interface PropsType extends StandardTextFieldProps {
  control: Control<any>;
  name: LoginNameType | RegisterNameType;
  rules?: any;
}

const HookTextField = ({ control, name, rules, ...props }: PropsType) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: "",
    rules: {
      ...rules,
      required: {
        value: true,
        message: "필수값을 입력해주세요",
      },
    },
  });

  return (
    <Box width="100%">
      <TextField value={value} onChange={onChange} error={!!error} {...props} />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Box>
  );
};

export default HookTextField;

const ErrorMessage = styled("p")(({ theme }) => ({
  color: red[500],
  fontSize: 12,
  margin: "10px 0 0 0",
}));
