import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import { blue, indigo } from "@mui/material/colors";

interface ChildrenType {
  url: string;
  name: string;
  urlList?: string[];
  children?: { url: string; name: ReactNode }[];
}

interface ItemType {
  url?: string;
  name: string;
  icon?: ReactNode;
  urlList: string[];
  children: [];
}

const ParentItem = ({ item }: { item: any }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const current = !!item.urlList.filter(
    (v: string) => pathname.indexOf(v) !== -1
  ).length;
  const [isOpen, setIsOpen] = useState(current);

  return (
    <Box component="li" maxHeight={isOpen ? "100vh" : "54.5px"}>
      <ListItemButton
        onClick={() => {
          setIsOpen(!isOpen);
          item.url && navigate(item.url);
        }}
        sx={{
          borderRadius: "7px",
          marginY: "8px",
          padding: "12px 15px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          flexWrap="nowrap"
        >
          <ListItemIcon
            sx={{
              color: blue["A700"],
              minWidth: "48px",
              svg: { fontSize: "1.2rem" },
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              fontWeight: current ? 500 : "inherit",
              color: current ? blue["A700"] : "inherit",
            }}
          >
            {item.name}
          </ListItemText>
          <ListItemIcon sx={{ minWidth: "16px" }}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        </Grid>
      </ListItemButton>
      <Children isOpen={isOpen}>
        {item.children.map((menu: any, idx: number) =>
          menu.children ? (
            <ParentItem item={menu} key={menu.name} />
          ) : (
            <MenuItem item={menu} key={idx} />
          )
        )}
      </Children>
    </Box>
  );
};

export default ParentItem;

const Children = styled("ul")(({ isOpen }: { isOpen: boolean }) => ({
  maxHeight: isOpen ? "100vh" : "0px",
  overflow: "hidden",
  transition: isOpen ? `max-height 0.4s ease-in` : "none",
}));
