import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import WelcomeImg from "../../assets/images/welcome.png";
import Logo from "../../components/auth/Logo";
import RegisterForm from "../../components/auth/RegisterForm";
import { blue } from "@mui/material/colors";

const Register = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Grid container height="100vh">
      <Logo />
      {!isMobile && (
        <Grid
          container
          item
          xs={4}
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "#fff" }}
        >
          <img src={WelcomeImg} alt="이미지" width="400" />
        </Grid>
      )}
      <Grid
        container
        item
        sm={8}
        justifyContent="center"
        alignItems="center"
        px={5}
        py={5}
        sx={{ backgroundColor: blue[50] }}
      >
        <RegisterForm />
      </Grid>
    </Grid>
  );
};

export default Register;
