import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import Header from "./Header";
import SideBar from "./SideBar";
import { blue, blueGrey } from "@mui/material/colors";

const Layout = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [isOpenSideBar, setIsOpenSideBar] = useState(!isMobile);

  const handleToggleSideBar = () => {
    setIsOpenSideBar(!isOpenSideBar);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        isOpenSideBar={isOpenSideBar}
        handleToggleSideBar={handleToggleSideBar}
      />
      <SideBar
        isOpenSideBar={isOpenSideBar}
        handleToggleSideBar={handleToggleSideBar}
      />
      <Box
        p={isMobile ? "30px 10px" : "40px"}
        sx={{
          height: "100vh",
          width: "100vw",
          overflow: "auto",
          backgroundColor: blueGrey[50],
        }}
      >
        <Toolbar sx={{ minHeight: "75px !important" }} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
