import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { blue, indigo } from "@mui/material/colors";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";

interface ItemType {
  url: string;
  name: string | ReactNode;
  icon?: ReactNode;
}

const MenuItem = ({ item }: { item: ItemType }) => {
  const { pathname } = useLocation();
  const current = pathname.indexOf(item.url) !== -1;

  return (
    <li>
      <ListItemButton
        href={item.url}
        sx={{
          backgroundColor: current ? `${blue[50]} !important` : "transparent",
          borderRadius: "7px",
          marginY: "8px",
          padding: "12px 15px",
        }}
      >
        <ListItemIcon
          sx={{
            color: blue["A700"],
            minWidth: "48px",
            svg: { fontSize: "1.2rem" },
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            fontWeight: current ? 500 : "inherit",
            color: current ? blue["A700"] : "inherit",
          }}
        >
          {item.name}
        </ListItemText>
      </ListItemButton>
    </li>
  );
};

export default MenuItem;
